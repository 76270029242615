<template>
  <div>
    <h3 class="title text_navy">
      Select Accessory
    </h3>
    <label class="form__label">
      <input
        v-model="models.item"
        type="text"
        class="form__input"
        placeholder="Type To Search"
      >
    </label>

    <ul class="list">
      <li
        v-for="elem in items"
        :key="elem.item_id"
        class="list__item"
        @click="select(elem)"
      >
        {{ elem.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import {simpleSearch} from '../../../helpers/search'

export default {
    name: 'AccessoriesItem',

    props: ['accessoriesItems', 'type'],

    data() {
        return {
            models: {
                item: ''
            },
            items: []
        }
    },
    watch: {
        'models.item'(n) {
            this.items = simpleSearch(n, this.accessoriesItems.data)
        },

        accessoriesItems() {
            this.items = this.accessoriesItems.data
        },
    },

    created(){
        this.items = this.accessoriesItems.data
    },

    methods: {
        select(elem) {
            this.$emit('selectItem', elem)
        },

        back(){
            this.$store.commit('accessories/currentItem', {style: 0})
        }
    },


}
</script>

<style scoped lang="scss">

  @import "../../../assets/scss/utils/vars";

  .form {
    &__label {

    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }
  }
</style>
